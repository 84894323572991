import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Select, Segmented } from "antd";
import TimeSelectorAdvanced from "components/TimeSelectorAdvanced";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./StackDashboard.module.less";
import GridView from "./components/GridView";
import WhiskerChart from "./components/WhiskerChart";
import SubstackGrid from "./SubstackGrid";

const baseViewOptions = [
  {
    label: "Stack View",
    value: "STACK_VIEW",
  },
];

const substackOptions = Array.from({ length: 16 }, (_, index) => ({
  label: `Substack ${index + 1}`,
  value: index + 1,
}));

const viewOptions = [...baseViewOptions, ...substackOptions];

export default function StackDashboard({ mode }) {
  const entityText = mode === "SUBSTACK" ? "Substack" : "Stack";

  const { stackId } = useParams();
  const selectedView = stackId ? parseInt(stackId, 10) : "STACK_VIEW";
  const [startTime, setStartTime] = useState(dayjs().add(-3, "hours"));
  const [endTime, setEndTime] = useState(dayjs());
  const [timeInterval, setTimeInterval] = useState("24h");
  const [timeMode, setTimeMode] = useState("REALTIME");
  const [pollInterval, setPollInterval] = useState(0);
  const [refetch, setRefetch] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [viewMode, setViewMode] = useState(searchParams.get("view") || "overview");
  const navigate = useNavigate();

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("view", viewMode);
    setSearchParams(newSearchParams);
  }, [viewMode, searchParams, setSearchParams]);

  const kpis = [
    { name: "Anode Inlet Flowrate", value: 4, unit: "L/min" },
    { name: "Anode Inlet Temp", value: 67, unit: "Deg C" },
    { name: "Anode Inlet Pressure", value: 4, unit: "barg" },
    { name: "H2 Flow Rate", value: 7, unit: "Kg/min" },
  ];

  const voltages = [
    {
      min: 1,
      avg: 1.4,
      max: 1.9,
      runtime: 216,
    },
    {
      min: 0.9,
      avg: 1.3,
      max: 1.7,
      runtime: 180,
    },
    {
      min: 1,
      avg: 1.38,
      max: 2,
      runtime: 142,
    },
    {
      min: 1.15,
      avg: 1.15,
      max: 1.75,
      runtime: 164,
    },
    {
      min: 0.82,
      avg: 1.04,
      max: 1.2,
      runtime: 184,
    },
    {
      min: 1.6,
      avg: 1,
      max: 1.9,
      runtime: 154,
    },
    {
      min: 0.85,
      avg: 1.1,
      max: 1.3,
      runtime: 199,
    },
    {
      min: 1.2,
      avg: 1.4,
      max: 2,
      runtime: 200,
    },
    {
      min: 1.05,
      avg: 1.3,
      max: 1.9,
      runtime: 180,
    },
    {
      min: 1.1,
      avg: 1.38,
      max: 1.92,
      runtime: 179,
    },
    {
      min: 0.9,
      avg: 1.26,
      max: 2,
      runtime: 140,
    },
    {
      min: 0.8,
      avg: 1.46,
      max: 1.85,
      runtime: 189,
    },
    {
      min: 1.1,
      avg: 1.36,
      max: 1.85,
      runtime: 190,
    },
    {
      min: 1,
      avg: 1.36,
      max: 1.85,
      runtime: 170,
    },
    {
      min: 1.1,
      avg: 1.18,
      max: 1.75,
      runtime: 150,
    },
    {
      min: 0.75,
      avg: 1,
      max: 1.48,
      runtime: 190,
    },
  ];

  const substackVoltages = [
    { min: 0.9, avg: 1.3, max: 1.8, runtime: 210 },
    { min: 1.1, avg: 1.4, max: 1.9, runtime: 185 },
    { min: 0.8, avg: 1.2, max: 1.7, runtime: 195 },
    { min: 1.0, avg: 1.35, max: 1.85, runtime: 172 },
    { min: 0.85, avg: 1.1, max: 1.5, runtime: 204 },
    { min: 1.2, avg: 1.45, max: 2.0, runtime: 160 },
    { min: 0.95, avg: 1.25, max: 1.75, runtime: 188 },
    { min: 1.15, avg: 1.38, max: 1.92, runtime: 176 },
    { min: 0.75, avg: 1.05, max: 1.4, runtime: 215 },
    { min: 1.3, avg: 1.5, max: 1.95, runtime: 142 },
    { min: 0.9, avg: 1.2, max: 1.6, runtime: 198 },
    { min: 1.05, avg: 1.32, max: 1.8, runtime: 165 },
    { min: 0.7, avg: 0.95, max: 1.3, runtime: 220 },
    { min: 1.25, avg: 1.45, max: 1.88, runtime: 152 },
    { min: 0.95, avg: 1.18, max: 1.65, runtime: 182 },
    { min: 1.1, avg: 1.36, max: 1.9, runtime: 170 },
    { min: 0.85, avg: 1.15, max: 1.55, runtime: 192 },
    { min: 1.2, avg: 1.42, max: 1.95, runtime: 158 },
    { min: 0.9, avg: 1.28, max: 1.75, runtime: 205 },
    { min: 1.05, avg: 1.3, max: 1.82, runtime: 174 },
    { min: 0.8, avg: 1.07, max: 1.45, runtime: 212 },
    { min: 1.15, avg: 1.4, max: 1.87, runtime: 166 },
    { min: 0.95, avg: 1.22, max: 1.7, runtime: 180 },
    { min: 1.3, avg: 1.48, max: 1.93, runtime: 147 },
    { min: 0.7, avg: 1.02, max: 1.38, runtime: 225 },
    { min: 1.1, avg: 1.35, max: 1.85, runtime: 162 },
    { min: 0.85, avg: 1.16, max: 1.6, runtime: 196 },
    { min: 1.2, avg: 1.44, max: 1.96, runtime: 154 },
    { min: 0.9, avg: 1.26, max: 1.78, runtime: 208 },
    { min: 1.05, avg: 1.33, max: 1.84, runtime: 168 },
  ];

  const renderContent = () => {
    if (selectedView === "STACK_VIEW") {
      return viewMode === "overview" ? (
        <WhiskerChart mode="STACK" voltages={voltages} />
      ) : (
        <GridView viewMode={viewMode} />
      );
    }
    return viewMode === "overview" ? <WhiskerChart mode="SUBSTACK" voltages={substackVoltages} /> : <SubstackGrid />;
  };
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.upperRow}>
          <div style={{ display: "flex", gap: 24 }}>
            <Select
              className={styles.select}
              options={viewOptions}
              placeholder="Select View"
              value={selectedView}
              onChange={(newView) =>
                navigate(`/stack-dashboard${Number.isInteger(newView) ? `/stacks/${newView}` : ""}?view=${viewMode}`)
              }
            />
            <Segmented
              value={viewMode}
              options={[
                { value: "overview", label: `${entityText} Overview` },
                { value: "individual", label: `Individual ${entityText} View` },
              ]}
              onChange={(value) => {
                setViewMode(value);
              }}
            />
          </div>

          <TimeSelectorAdvanced
            {...{
              setStartTime,
              setEndTime,
              startTime,
              endTime,
              timeInterval,
              setTimeInterval,
              timeMode,
              setTimeMode,
              pollInterval,
              setPollInterval,
              setRefetch,
              containerStyle: { flex: "none" },
            }}
          />
        </div>
        {mode !== "SUBSTACK" && (
          <div className={styles.kpiRow}>
            {kpis.map((kpi) => (
              <div key={kpi.name} className={styles.kpiCard}>
                <div className={styles.kpiName}>{kpi.name}</div>
                <div className={styles.kpiVal}>{kpi.value}</div>
                <div className={styles.kpiUnit}>{kpi.unit}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.content}>{renderContent()}</div>
      {/* <div className={styles.content}>a</div> */}
    </div>
  );
}
