import React from "react";
import { Segmented, Button } from "antd";

import styles from "./SimpleRoleSelector.module.less";

export default function SimpleRoleSelector({
  initialRoleSlug,
  onRolesUpdate,
  initialMode,
  actionText,
  loading = false,
  buttonDisabled,
  selectedRole,
  setSelectedRole,
  roles,
  disabled,
}) {
  return (
    <div className={styles.segmentedContainer}>
      <Segmented
        block
        value={selectedRole || undefined}
        defaultValue="role_viewer"
        onChange={(newRole) => setSelectedRole(newRole)}
        disabled={disabled}
        options={roles.map((role) => ({
          value: role.slug,
          label: (
            <div
              className={`${styles.roleCard} ${
                (selectedRole === role.slug || (!selectedRole && role.slug === "role_viewer")) && styles.selected
              }`}
            >
              <h4>{role.name}</h4>
              <div className={styles.roleDescriptionContainer}>
                <div>{role.description} </div>
              </div>
            </div>
          ),
        }))}
      />
      {!disabled && (
        <Button
          disabled={
            buttonDisabled !== undefined
              ? buttonDisabled
              : initialMode !== "ADVANCED" && initialRoleSlug === selectedRole
          }
          loading={loading}
          onClick={() => onRolesUpdate([selectedRole])}
          type="primary"
          className={styles.updateButton}
        >
          {actionText}
        </Button>
      )}
    </div>
  );
}
