import SHA256 from "sha.js/sha256";
import seedrandom from "seedrandom";

export function generateData(value, length, seed) {
  const hash = new SHA256().update(seed).digest("hex");
  const seedValue = parseInt(hash, 16);

  const rng = seedrandom(seedValue.toString());

  const newValues = Array.from({ length: length - 1 }, () => {
    const variation = value * 0.02;
    const newValue = value + (rng() * 2 * variation - variation);
    return Number(newValue.toPrecision(4));
  });

  newValues.push(value);

  return newValues;
}

export function generateBoxWhiskerData({ average, boxSpread, whiskerExtension, n, seed }) {
  if (
    typeof average !== "number" ||
    typeof boxSpread !== "number" ||
    typeof whiskerExtension !== "number" ||
    typeof n !== "number"
  ) {
    throw new Error("All inputs must be numbers");
  }

  const random = seed !== undefined ? seedrandom(seed) : Math.random;

  const q1 = average - boxSpread / 2;
  const q3 = average + boxSpread / 2;
  const iqr = boxSpread;
  const whiskerLower = q1 - whiskerExtension * iqr;
  const whiskerUpper = q3 + whiskerExtension * iqr;

  const dataPoints = [];
  const totalPoints = n;

  for (let i = 0; i < totalPoints; i++) {
    let value;
    const randVal = typeof random === "function" ? random() : Math.random();

    if (randVal < 0.25) {
      value = whiskerLower + (typeof random === "function" ? random() : Math.random()) * (q1 - whiskerLower);
    } else if (randVal < 0.5) {
      value =
        whiskerLower + (q1 - whiskerLower) + (typeof random === "function" ? random() : Math.random()) * (average - q1);
    } else if (randVal < 0.75) {
      value = average + (typeof random === "function" ? random() : Math.random()) * (q3 - average);
    } else {
      value = q3 + (typeof random === "function" ? random() : Math.random()) * (whiskerUpper - q3);
    }

    dataPoints.push(value);
  }

  return dataPoints;
}

export function generateRandomString(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);

    result += characters[randomIndex];
  }

  return result;
}
