/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { Button, Select } from "antd";

import { ReactComponent as RefreshIcon } from "assets/refresh-icon.svg";
import { DownloadOutlined } from "@ant-design/icons";
import { generateData } from "utils/generate-data";

import { StackPerformanceChart, StacksModal } from "./components";
import {
  TrendDisplay,
  H2ProductionBarChart,
  OperationsModeBarChart,
  SingleChartDisplay,
  DoubleChartDisplay,
  PanelModal,
} from "../components";

const operationalMode = "ELECTROLYZING";

const operationalModes = {
  ELECTROLYZING: "Electrolyzing",
  STANDBY: "Standby",
  OFF: "Off/Other",
};

const intervals = {
  "1h": "Last hour",
  "3h": "Last 3 hours",
  "24h": "Last 24 hours",
  "3d": "Last 3 days",
  "7d": "Last 7 days",
  "30d": "Last 30 days",
};

const refreshOptions = [
  {
    label: "Refresh disabled",
    value: 0,
  },
  {
    label: "5 sec",
    value: 5000,
  },
  {
    label: "15 sec",
    value: 15000,
  },
  {
    label: "min",
    value: 60000,
  },
  {
    label: "5 min",
    value: 300000,
  },
  {
    label: "15 min",
    value: 900000,
  },
  {
    label: "30 min",
    value: 1800000,
  },
  {
    label: "hour",
    value: 3600000,
  },
];

const stackPerformanceData = {
  voltages: [
    {
      min: 1,
      avg: 1.4,
      max: 1.9,
      runtime: 246,
    },
    {
      min: 0.9,
      avg: 1.3,
      max: 1.7,
      runtime: 246,
    },
    {
      min: 1,
      avg: 1.38,
      max: 2,
      runtime: 246,
    },
    {
      min: 1.15,
      avg: 1.65,
      max: 1.75,
      runtime: 246,
    },
    {
      min: 0.82,
      avg: 1.04,
      max: 1.2,
      runtime: 234,
    },
    {
      min: 1.6,
      avg: 1.78,
      max: 1.9,
      runtime: 234,
    },
    {
      min: 0.85,
      avg: 1.1,
      max: 1.3,
      runtime: 234,
    },
    {
      min: 1.2,
      avg: 1.4,
      max: 2,
      runtime: 234,
    },
    {
      min: 1.05,
      avg: 1.3,
      max: 1.9,
      runtime: 200,
    },
    {
      min: 1.1,
      avg: 1.38,
      max: 1.92,
      runtime: 200,
    },
    {
      min: 0.9,
      avg: 1.56,
      max: 2,
      runtime: 200,
    },
    {
      min: 0.8,
      avg: 1.46,
      max: 1.85,
      runtime: 200,
    },
    {
      min: 1.1,
      avg: 1.36,
      max: 1.85,
      runtime: 180,
    },
    {
      min: 1,
      avg: 1.36,
      max: 1.85,
      runtime: 180,
    },
    {
      min: 1.1,
      avg: 1.28,
      max: 1.75,
      runtime: 180,
    },
    {
      min: 0.75,
      avg: 1,
      max: 1.48,
      runtime: 180,
    },
  ],
};

export default function SystemOverviewService({ styles }) {
  const [selectedInterval, setSelectedInterval] = useState("24h");
  const [stackPerformanceInterval, setStackPerformanceInterval] = useState("7d");
  const [selectedRefresh, setSelectedRefresh] = useState(5000);
  const [panelModalOpen, setPanelModalOpen] = useState(false);
  const [panelModalData, setPanelModalData] = useState();

  const [stacksModalOpen, setStacksModalOpen] = useState(false);

  const powerConsumptionData = {
    title: "Total Plant Power Consumption",
    data: generateData(104, 6, selectedInterval),
    yAxisTitle: "Power Consumption",
    xAxisTitle: "Time",
    unit: "MW",
  };
  const plantCapacityData = {
    title: "Power Level",
    data: generateData(100, 6, selectedInterval),
    yAxisTitle: "Power Level (% of 45 TDP)",
    xAxisTitle: "Time",
    unit: "%",
  };
  const h2ProductionRateData = {
    title: "H2 Production Rate",
    data: generateData(1900, 6, selectedInterval),
    yAxisTitle: "Production Rate",
    xAxisTitle: "Time",
    unit: "Kg/Hr",
  };

  const waterConsumptionData = {
    title: "Water Consumption",
    data: generateData(13, 6, selectedInterval),
    yAxisTitle: "Water Consumption",
    xAxisTitle: "Time",
    unit: "L/kg H₂",
  };

  const wasteWaterDischargedData = {
    title: "Wastewater Discharged",
    data: generateData(4, 6, selectedInterval),
    yAxisTitle: "Wastewater Discharged",
    xAxisTitle: "Time",
    unit: "L/kg H₂",
  };

  const efficiencyDataAC = {
    title: "Plant AC Efficiency",
    data: generateData(54.3, 6, selectedInterval),
    yAxisTitle: "AC Efficiency",
    xAxisTitle: "Time",
    unit: "kWh/kg H₂",
  };

  const efficiencyDataDC = {
    title: "Plant DC Efficiency",
    data: generateData(52, 6, selectedInterval),
    yAxisTitle: "DC Efficiency",
    xAxisTitle: "Time",
    unit: "kWh/kg H₂",
  };

  const outputPressuredata = {
    title: "H₂ Output Pressure",
    data: generateData(32, 6, selectedInterval),
    yAxisTitle: "Output Pressure",
    xAxisTitle: "Time",
    unit: "Barg",
  };

  const outputTemperatureData = {
    title: "H₂ Output Temperature",
    data: generateData(20, 6, selectedInterval),
    yAxisTitle: "Output Temperature",
    xAxisTitle: "Time",
    unit: "°C",
  };

  const waterResistivityData = {
    title: "Water Resistivity To Stack",
    data: generateData(12.9, 6, selectedInterval),
    yAxisTitle: "Water Resistivity",
    xAxisTitle: "Time",
    unit: "MΩ-cm",
  };

  const rectifierConsumptionData = {
    title: "Rectifier Power Consumption",
    data: generateData(102, 6, selectedInterval),
    yAxisTitle: "Power Consumption",
    xAxisTitle: "Time",
    unit: "MW",
  };

  const lflData = {
    title: "% LFL",
    data: generateData(2.9, 6, selectedInterval),
    yAxisTitle: "% LFL",
    xAxisTitle: "Time",
    unit: "%",
  };

  const onPanelClick = (data) => {
    setPanelModalOpen(true);
    setPanelModalData(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.modesContainer}>
          <div
            className={`${styles.subtitle} ${styles.modesSubtitle}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            Current Operational Mode:
          </div>
          {Object.entries(operationalModes).map(([mode, label]) => (
            <div key={mode} className={`${styles.modeOption} ${mode === operationalMode && styles.selectedMode}`}>
              {label}
            </div>
          ))}
        </div>
        <Button icon={<DownloadOutlined />} className={styles.button}>
          Download Report
        </Button>
      </div>
      <div className={styles.upperCharts}>
        <div className={styles.card}>
          <div className={styles.rowSeparated}>
            <h2 className={styles.subtitle}>Daily H2 Production</h2>
            <TrendDisplay percentage={12} />
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <H2ProductionBarChart data={[22, 12, 8, 32, 36, 45, 35]} />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.subtitle}>Daily Operation Mode Summary</div>
          <div style={{ height: "100%", width: "100%" }}>
            <OperationsModeBarChart
              data={[
                [6, 7.5],
                [0, 0],
                [0, 0],
                [6, 10],
                [5, 18],
                [8, 16],
                [2, 16],
              ]}
            />
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <div className={styles.subtitle}>Stack Performance During Electrolysis</div>
            <Select
              size="small"
              value={stackPerformanceInterval}
              className={styles.stacksIntervalSelector}
              onChange={(newInterval) => setStackPerformanceInterval(newInterval)}
              options={Object.entries(intervals).map(([interval, label]) => ({
                value: interval,
                label,
              }))}
            />
          </div>
          <div style={{ height: "100%", width: "100%", cursor: "pointer" }} onClick={() => setStacksModalOpen(true)}>
            <StackPerformanceChart voltages={stackPerformanceData.voltages} />
          </div>
        </div>
      </div>
      <div className={styles.botSectionHeader}>
        <div className={styles.title}>
          H<sub>2</sub> Production Performance Overview
        </div>
        <div className={styles.selectors}>
          <Button shape="circle" className={styles.circleButton}>
            <RefreshIcon />
          </Button>
          <Select
            className={styles.refreshSelector}
            value={selectedRefresh}
            onChange={(newRefreshVal) => setSelectedRefresh(newRefreshVal)}
            options={refreshOptions.map((o) => ({
              value: o.value,
              label: o.value === 0 ? o.label : `Refresh every ${o.label}`,
            }))}
          />
          <Select
            value={selectedInterval}
            className={styles.intervalSelector}
            onChange={(newInterval) => setSelectedInterval(newInterval)}
            options={Object.entries(intervals).map(([interval, label]) => ({
              value: interval,
              label,
            }))}
          />
        </div>
      </div>
      <div className={styles.bottomCharts}>
        <div className={styles.card} style={{ gridArea: "A" }}>
          <SingleChartDisplay data={powerConsumptionData} onPanelClick={onPanelClick} />
        </div>
        <div className={styles.card} style={{ gridArea: "B" }}>
          <SingleChartDisplay data={plantCapacityData} onPanelClick={onPanelClick} />
        </div>
        <div className={styles.card} style={{ gridArea: "C" }}>
          <SingleChartDisplay data={h2ProductionRateData} onPanelClick={onPanelClick} />
        </div>
        <div className={styles.card} style={{ gridArea: "D" }}>
          <DoubleChartDisplay
            chart1={{ data: waterConsumptionData }}
            chart2={{ data: wasteWaterDischargedData }}
            onPanelClick={onPanelClick}
          />
        </div>
        <div className={styles.card} style={{ gridArea: "E" }}>
          <DoubleChartDisplay
            chart1={{ data: efficiencyDataAC }}
            chart2={{ data: efficiencyDataDC }}
            onPanelClick={onPanelClick}
          />
        </div>
        <div className={styles.card} style={{ gridArea: "F" }}>
          <DoubleChartDisplay
            chart1={{ data: outputPressuredata }}
            chart2={{ data: outputTemperatureData }}
            onPanelClick={onPanelClick}
          />
        </div>
        <div className={styles.card} style={{ gridArea: "G" }}>
          <SingleChartDisplay data={waterResistivityData} onPanelClick={onPanelClick} />
        </div>
        <div className={styles.card} style={{ gridArea: "H" }}>
          <SingleChartDisplay data={rectifierConsumptionData} onPanelClick={onPanelClick} />
        </div>
        <div className={styles.card} style={{ gridArea: "I" }}>
          <SingleChartDisplay data={lflData} onPanelClick={onPanelClick} />
        </div>
      </div>
      <PanelModal open={panelModalOpen} setOpen={setPanelModalOpen} data={panelModalData} />
      <StacksModal open={stacksModalOpen} setOpen={setStacksModalOpen} data={stackPerformanceData.voltages} />
    </div>
  );
}
