/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { ReactComponent as StackIcon } from "assets/stack-detailed.svg";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Checkbox } from "antd";
import { ReactComponent as CarouselArrowLeft } from "assets/carousel-arrow-left.svg";
import { ReactComponent as CarouselArrowRight } from "assets/carousel-arrow-right.svg";

import styles from "./GridView.module.less";

export default function GridView({ viewMode }) {
  const navigate = useNavigate();

  const stacks = [
    {
      name: "Stack 1A",
      id: "131",
      type: 1,
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 1B",
      id: "187",
      type: 2,
      maxV: 1.96,
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 2A",
      type: 2,

      id: "199",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 2B",
      type: 2,

      id: "049",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 3A",
      type: 2,

      id: "181",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 3B",
      type: 2,

      id: "291",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 4A",
      type: 2,

      id: "118",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 4B",
      type: 2,

      id: "286",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 5A",
      type: 2,

      id: "177",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 5B",
      type: 2,

      id: "148",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 6A",
      type: 2,

      id: "200",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 6B",
      type: 2,

      id: "074",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 7A",
      type: 1,

      id: "183",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 7B",
      type: 2,

      id: "251",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 8A",
      type: 2,

      id: "124",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
    {
      name: "Stack 8B",
      type: 2,

      id: "204",
      anodeOutletTemperature: 80,
      AnodeOutletPressure: 4,
      CathodeOutletTemperature: 73,
      CathodeOutletPressure: 30,
    },
  ];
  return (
    <div className={styles.grid}>
      {stacks.map((s, idx) => (
        <div
          key={s.id}
          className={styles.stackCard}
          onClick={() => navigate(`/stack-dashboard/stacks/${idx + 1}?view=${viewMode}`)}
        >
          <div className={styles.header}>
            <div className={styles.headerSection}>
              <StackIcon width={20} heigth={20} />
              <div>{s.name}</div>
            </div>
            <div className={styles.headerSection}>
              ID <div className={styles.displayField}>{s.id}</div>
            </div>
          </div>
          <div className={styles.content}>
            <Carousel
              className={styles.carousel}
              showIndicators={false}
              showStatus={false}
              renderArrowPrev={(clickHandler, hasPrev) =>
                hasPrev && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      clickHandler();
                    }}
                    className={styles.arrowLeft}
                  >
                    <CarouselArrowLeft height={24} width={24} />
                  </div>
                )
              }
              renderArrowNext={(clickHandler, hasNext) =>
                hasNext && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      clickHandler();
                    }}
                    className={styles.arrowRight}
                  >
                    <CarouselArrowRight height={24} width={24} />
                  </div>
                )
              }
            >
              <div className={styles.carouselItem}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className={styles.listContainer}>
                    <div className={styles.listItem}>
                      <div>Max. V</div> <div className={styles.displayField}>1.96</div>
                    </div>
                    <div className={styles.listItem}>
                      <div>Avg. V</div> <div className={styles.displayField}>1.92</div>
                    </div>
                    <div className={styles.listItem}>
                      <div>Min. V</div> <div className={styles.displayField}>1.89</div>
                    </div>
                    <div className={styles.listItem}>
                      <div>Std. Dev</div> <div className={styles.displayField}>1</div>
                    </div>
                  </div>
                  <div className={styles.listContainer}>
                    <div className={styles.listItem}>
                      <div>Total V</div> <div className={styles.displayField}>576</div>
                    </div>
                    <div className={styles.listItem}>
                      <div>LFL%</div> <div className={styles.displayField}>3%</div>
                    </div>
                    <div className={styles.listItem}>
                      <div>Total Bolt Ld</div> <Checkbox checked />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.carouselItem}>
                <div className={styles.listContainer}>
                  <div className={styles.listItem}>
                    <div>Annode Outlet Temp</div> <div className={styles.displayField}>{s.anodeOutletTemperature}</div>
                  </div>
                  <div className={styles.listItem}>
                    <div>Annode Outlet Pressure</div> <div className={styles.displayField}>{s.AnodeOutletPressure}</div>
                  </div>
                  <div className={styles.listItem}>
                    <div>Cathode Outlet Temp</div>{" "}
                    <div className={styles.displayField}>{s.CathodeOutletTemperature}</div>
                  </div>
                  <div className={styles.listItem}>
                    <div>Cathode Outlet Pressure</div>{" "}
                    <div className={styles.displayField}>{s.CathodeOutletPressure}</div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      ))}
    </div>
  );
}
