import { isCustomerApm, isServiceApm } from "utils/auth-utils";

import { modules as modulesFn } from "./modules";

const isService = isServiceApm();
const isCustomer = isCustomerApm();

export const menuItems = () => {
  const modules = modulesFn();

  if (isCustomer) {
    return [
      modules.home,
      {
        name: "Hydrogen Plant",
        key: "hydrogen-plant",
        subitems: [
          modules["system-overview"],
          modules.assets,
          modules["alarm-analytics"],
          modules["document-center"],
          modules["service-support"],
        ],
      },
      {
        name: "General",
        key: "general",
        subitems: [modules["roles-admin"], modules["help-center"], modules.notifications],
      },
    ];
  }

  if (isService) {
    return [
      modules.home,
      {
        name: "Dashboards",
        key: "dashboards-section",
        subitems: [modules["system-overview"], modules["stack-dashboard"], modules.dashboards],
      },
      {
        name: "Monitoring",
        key: "monitoring",
        subitems: [modules.assets, modules["alarm-analytics"]],
      },
      {
        name: "Service Support",
        key: "service-support",
        subitems: [modules["document-center"], modules["work-requests"]],
      },
      {
        name: "General",
        key: "general",
        subitems: [modules["roles-admin"], modules["help-center"], modules.notifications],
      },
    ];
  }

  return [
    modules.home,
    modules.notifications,
    modules.dashboards,
    {
      name: "Asset Monitoring",
      key: "general-section",
      subitems: [modules.assets, modules.quickboard, modules["alarm-rules"], modules["alarm-acknowledgement"]],
    },
    {
      name: "Data Analytics",
      key: "data-analytics",
      requiredFeature: "data-analytics",
      subitems: [modules.grafana],
    },
    {
      name: "Business Intelligence",
      key: "business-intelligence-section",
      requiredFeature: "superset",
      subitems: [modules["business-intelligence"]],
    },
    {
      name: "Operations & Maintenance",
      key: "operations-&-maintenance",
      subitems: [modules["work-requests"]],
    },
    {
      name: "Safety Management",
      key: "safety-management",
      subitems: [modules["incident-reports"]],
    },
    {
      name: "Settings",
      key: "settings",
      subitems: [modules["roles-admin"], modules["data-partners"], modules["super-admin"]],
    },
  ];
};

export const userMenuItems = () => {
  const modules = modulesFn();
  return [modules.account];
};
